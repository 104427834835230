
body {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.6em;
}
a {
  padding: 0;
  -webkit-transition: color 0.1s ease-in-out, border 0.1s ease-in-out;
  -moz-transition: color 0.1s ease-in-out, border 0.1s ease-in-out;
  -ms-transition: color 0.1s ease-in-out, border 0.1s ease-in-out;
  -o-transition: color 0.1s ease-in-out, border 0.1s ease-in-out;
  transition: color 0.1s ease-in-out, border 0.1s ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
p {
  padding-bottom: 0;
  margin-bottom: 20px;
  margin-top: 0;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  padding: 0;
  margin: 0 0 15px;
  font-family: 'Open Sans', Arial, sans-serif;
  line-height: 100%;
  font-weight: 400;
  letter-spacing: 0;
}
h1.pi-has-bg,
.h1.pi-has-bg,
h2.pi-has-bg,
.h2.pi-has-bg,
h3.pi-has-bg,
.h3.pi-has-bg,
h4.pi-has-bg,
.h4.pi-has-bg,
h5.pi-has-bg,
.h5.pi-has-bg,
h6.pi-has-bg,
.h6.pi-has-bg {
  overflow: hidden;
}
h1.pi-has-bg:after,
.h1.pi-has-bg:after,
h2.pi-has-bg:after,
.h2.pi-has-bg:after,
h3.pi-has-bg:after,
.h3.pi-has-bg:after,
h4.pi-has-bg:after,
.h4.pi-has-bg:after,
h5.pi-has-bg:after,
.h5.pi-has-bg:after,
h6.pi-has-bg:after,
.h6.pi-has-bg:after {
  position: relative;
  left: 18px;
  content: "";
  display: inline-block;
  width: 200%;
  height: 0.75em;
  margin: 0 -200% 0 0;
}
h1.pi-has-border,
.h1.pi-has-border,
h2.pi-has-border,
.h2.pi-has-border,
h3.pi-has-border,
.h3.pi-has-border,
h4.pi-has-border,
.h4.pi-has-border,
h5.pi-has-border,
.h5.pi-has-border,
h6.pi-has-border,
.h6.pi-has-border {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
h1.pi-has-border:after,
.h1.pi-has-border:after,
h2.pi-has-border:after,
.h2.pi-has-border:after,
h3.pi-has-border:after,
.h3.pi-has-border:after,
h4.pi-has-border:after,
.h4.pi-has-border:after,
h5.pi-has-border:after,
.h5.pi-has-border:after,
h6.pi-has-border:after,
.h6.pi-has-border:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}
h1.pi-has-tall-border:after,
.h1.pi-has-tall-border:after,
h2.pi-has-tall-border:after,
.h2.pi-has-tall-border:after,
h3.pi-has-tall-border:after,
.h3.pi-has-tall-border:after,
h4.pi-has-tall-border:after,
.h4.pi-has-tall-border:after,
h5.pi-has-tall-border:after,
.h5.pi-has-tall-border:after,
h6.pi-has-tall-border:after,
.h6.pi-has-tall-border:after {
  height: 3px;
}
h1.pi-has-short-border.pi-pull-right:after,
.h1.pi-has-short-border.pi-pull-right:after,
h2.pi-has-short-border.pi-pull-right:after,
.h2.pi-has-short-border.pi-pull-right:after,
h3.pi-has-short-border.pi-pull-right:after,
.h3.pi-has-short-border.pi-pull-right:after,
h4.pi-has-short-border.pi-pull-right:after,
.h4.pi-has-short-border.pi-pull-right:after,
h5.pi-has-short-border.pi-pull-right:after,
.h5.pi-has-short-border.pi-pull-right:after,
h6.pi-has-short-border.pi-pull-right:after,
.h6.pi-has-short-border.pi-pull-right:after {
  left: auto;
  right: 0;
}
h3.pi-has-tall-border:after,
.h3.pi-has-tall-border:after,
h4.pi-has-tall-border:after,
.h4.pi-has-tall-border:after,
h5.pi-has-tall-border:after,
.h5.pi-has-tall-border:after,
h6.pi-has-tall-border:after,
.h6.pi-has-tall-border:after {
  height: 2px;
}
h1.pi-has-short-border:after,
.h1.pi-has-short-border:after,
h2.pi-has-short-border:after,
.h2.pi-has-short-border:after,
h3.pi-has-short-border:after,
.h3.pi-has-short-border:after,
h4.pi-has-short-border:after,
.h4.pi-has-short-border:after,
h5.pi-has-short-border:after,
.h5.pi-has-short-border:after,
h6.pi-has-short-border:after,
.h6.pi-has-short-border:after {
  width: 50px;
}
.pi-text-center h1.pi-has-short-border:after,
.pi-text-center .h1.pi-has-short-border:after,
.pi-text-center h2.pi-has-short-border:after,
.pi-text-center .h2.pi-has-short-border:after,
.pi-text-center h3.pi-has-short-border:after,
.pi-text-center .h3.pi-has-short-border:after,
.pi-text-center h4.pi-has-short-border:after,
.pi-text-center .h4.pi-has-short-border:after,
.pi-text-center h5.pi-has-short-border:after,
.pi-text-center .h5.pi-has-short-border:after,
.pi-text-center h6.pi-has-short-border:after,
.pi-text-center .h6.pi-has-short-border:after {
  left: 50%;
  margin: 0 0 0 -25px;
}
h1,
.h1 {
  font-size: 50px;
  line-height: 1.2em;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: -1px;
}
h2,
.h2 {
  font-size: 30px;
  line-height: 1.3em;
  margin-bottom: 10px;
  letter-spacing: 0;
}
h3,
.h3 {
  font-size: 26px;
  line-height: 1.2em;
  margin-bottom: 10px;
}
h4,
.h4 {
  font-size: 20px;
  line-height: 1.3em;
  margin-bottom: 10px;
  letter-spacing: 0;
}
h5,
.h5 {
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 10px;
  letter-spacing: 0;
}
h6,
.h6 {
  font-size: 14px;
  line-height: 1.6em;
  margin-bottom: 10px;
  letter-spacing: 0;
  font-weight: 600;
}
.h1-hero {
  font-size: 54px;
  letter-spacing: -1px;
}
h1.pi-title-beside-image,
.h1.pi-title-beside-image {
  margin-top: -11px;
}
h2.pi-title-beside-image,
.h2.pi-title-beside-image,
h3.pi-title-beside-image,
.h3.pi-title-beside-image,
h4.pi-title-beside-image,
.h4.pi-title-beside-image {
  margin-top: -8px;
}
h5.pi-title-beside-image,
.h5.pi-title-beside-image,
h6.pi-title-beside-image,
.h6.pi-title-beside-image {
  margin-top: -5px;
}
.lead-16 {
  font-size: 16px;
  line-height: 1.6em;
}
.lead-18 {
  font-size: 18px;
  line-height: 1.5em;
}
.lead-20 {
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 300;
}
.lead-22 {
  font-size: 22px;
  line-height: 1.4em;
  font-weight: 300;
}
.lead-24 {
  font-size: 24px;
  line-height: 1.4em;
  font-weight: 300;
}
.lead-26 {
  font-size: 26px;
  line-height: 1.4em;
  font-weight: 300;
}
.lead-28 {
  font-size: 26px;
  line-height: 1.4em;
  font-weight: 300;
}
.lead-30 {
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 300;
}
.lead-32 {
  font-size: 32px;
  line-height: 1.3em;
  font-weight: 300;
}
.lead-34 {
  font-size: 34px;
  line-height: 1.3em;
  font-weight: 300;
}
.pi-404 {
  font-size: 160px;
  line-height: 1em;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
.pi-small-text {
  font-size: 13px;
  line-height: 1.6em !important;
}
.pi-smaller-text {
  font-size: 11px;
  line-height: 1.5em !important;
}
.pi-or {
  margin-left: 12px;
  margin-right: 12px;
}
.pi-title {
  margin-bottom: 25px;
}
.pi-weight-300 {
  font-weight: 300 !important;
}
.pi-weight-400 {
  font-weight: 400 !important;
}
.pi-weight-600 {
  font-weight: 600 !important;
}
.pi-weight-700 {
  font-weight: 700 !important;
}
.pi-uppercase {
  text-transform: uppercase;
}
.pi-letter-spacing {
  letter-spacing: 1px;
}
.pi-letter-spacing-2 {
  letter-spacing: 2px;
}
.pi-letter-spacing-3 {
  letter-spacing: 3px;
}
.pi-letter-spacing-4 {
  letter-spacing: 4px;
}
.pi-italic {
  font-style: italic;
}
.pi-text-shadow {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.pi-text-shadow-white {
  text-shadow: 0 1px 0 #fff;
}
.pi-p-half {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.pi-p-two-thirds {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
.pi-margin-top-minus-15 {
  margin-top: -15px !important;
}
.pi-margin-top-minus-10 {
  margin-top: -10px !important;
}
.pi-margin-top-minus-5 {
  margin-top: -5px !important;
}
.pi-margin-top-5 {
  margin-top: 5px !important;
}
.pi-margin-top-10 {
  margin-top: 10px !important;
}
.pi-margin-top-15 {
  margin-top: 15px !important;
}
.pi-margin-bottom-5 {
  margin-bottom: 5px !important;
}
.pi-margin-bottom-10 {
  margin-bottom: 10px !important;
}
.pi-margin-bottom-15 {
  margin-bottom: 15px !important;
}
.pi-margin-bottom-20 {
  margin-bottom: 20px !important;
}
.pi-margin-bottom-25 {
  margin-bottom: 25px !important;
}
.pi-margin-bottom-30 {
  margin-bottom: 30px !important;
}
.pi-margin-bottom-35 {
  margin-bottom: 35px !important;
}
.pi-margin-bottom-40 {
  margin-bottom: 40px !important;
}
.pi-margin-bottom-50 {
  margin-bottom: 50px !important;
}
.pi-margin-bottom-60 {
  margin-bottom: 60px !important;
}
.pi-margin-bottom-70 {
  margin-bottom: 70px !important;
}
.pi-margin-bottom-80 {
  margin-bottom: 80px !important;
}
.pi-margin-bottom-90 {
  margin-bottom: 90px !important;
}
.pi-margin-bottom-100 {
  margin-bottom: 100px !important;
}
small {
  font-size: 13px;
}
.pi-text-bg-dark,
.pi-text-bg-base,
.pi-text-bg-green,
.pi-text-bg-yellow {
  padding: 0 4px 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.pi-text-bg-dark {
  background: #2f3746;
  color: #d8e1ed;
}
.pi-text-bg-green {
  background: #57d57f;
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-text-bg-yellow {
  background: #fffcad;
  color: #613a28;
}
.pi-text-opacity-50 {
  opacity: 0.5 !important;
}
.pi-text-opacity-70 {
  opacity: 0.7 !important;
}
.pi-text-purple {
  color: #7016c3 !important;
}
.pi-text-blue {
  color: #0f80ba !important;
}
.pi-text-turquoise {
  color: #00aab3 !important;
}
.pi-text-green {
  color: #00b348 !important;
}
.pi-text-lime {
  color: #5db300 !important;
}
.pi-text-yellow {
  color: #d7dd2d !important;
}
.pi-text-orange {
  color: #f75f24 !important;
}
.pi-text-red {
  color: #d72c10 !important;
}
.pi-text-pink {
  color: #ed2f72 !important;
}
.pi-text-dark {
  color: #2e343c !important;
}
.pi-text-grey {
  color: #737A81 !important;
}
.pi-text-silver {
  color: #bdc3ca !important;
}
.pi-text-white {
  color: #fff !important;
}
.pi-text-white-for-dark {
  color: #DBE6E7 !important;
}
.pi-text-base {
  opacity: 1 !important;
}
.pi-text-base-dark {
  opacity: 1 !important;
}
ul,
ol,
dl {
  padding-left: 1.2em;
  padding-bottom: 0;
  margin-bottom: 20px;
}
ol {
  padding-left: 1.6em;
}
ul li,
ol li,
dl li {
  margin: 4px 0;
}
.pi-list-w-icons {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  -webkit-padding-start: 0;
}
.pi-list-w-icons i {
  margin-right: 10px;
}
.pi-pull-center {
  display: block;
  margin: 0 auto;
}
.pi-icon {
  font-size: 14px;
}
.pi-icon.pi-icon-big {
  font-size: 42px;
}
.pi-icon.pi-icon-bigger {
  font-size: 70px;
}
.pi-icon-circle,
.pi-icon-square {
  position: relative;
  margin: 0 0 -8px 0;
  display: inline-block;
  font-size: 14px;
  width: 26px;
  height: 26px;
  overflow: hidden;
  line-height: 25px;
  text-align: center;
}
.pi-icon-circle.pi-icon-big,
.pi-icon-square.pi-icon-big {
  width: 70px;
  height: 70px;
  font-size: 34px;
  line-height: 70px;
}
.pi-icon-circle.pi-icon-big.pi-icon-contur,
.pi-icon-square.pi-icon-big.pi-icon-contur {
  line-height: 68px;
}
.pi-icon-circle.pi-icon-bigger,
.pi-icon-square.pi-icon-bigger {
  width: 130px;
  height: 130px;
  font-size: 50px;
  line-height: 130px;
}
.pi-icon-circle.pi-icon-bigger.pi-icon-contur,
.pi-icon-square.pi-icon-bigger.pi-icon-contur {
  line-height: 128px;
}
.pi-icon-square {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.pi-icon-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.pi-icon-left {
  margin-right: 5px;
}
.pi-icon-right {
  margin-left: 5px;
}
abbr,
acronym {
  text-transform: uppercase;
  font-weight: 600;
}
abbr:hover,
acronym:hover {
  cursor: help;
}
code,
pre {
  font-family: Consolas, "Courier New", monospace;
  background-color: #FAF5E2;
  text-shadow: 0 1px 0 #fff;
  color: #5F443C;
  -webkit-box-shadow: inset 0 1px 0 #f0e7d3;
  -moz-box-shadow: inset 0 1px 0 #f0e7d3;
  box-shadow: inset 0 1px 0 #f0e7d3;
  border-radius: 3px;
}
code {
  padding: 2px 4px;
  text-shadow: 0 1px 0 #fffdec;
  white-space: nowrap;
}
pre {
  display: block;
  padding: 20px;
  margin-bottom: 20px;
  word-break: break-all;
  word-wrap: break-word;
}
pre.prettyprint {
  margin-bottom: 20px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
}
.pre-scrollable {
  max-height: 200px;
  overflow-y: scroll;
}
.pi-section-base {
  color: #dee7f0;
}
.pi-section-base h1,
.pi-section-base .h1,
.pi-section-base h2,
.pi-section-base .h2,
.pi-section-base h3,
.pi-section-base .h3,
.pi-section-base h4,
.pi-section-base .h4,
.pi-section-base h5,
.pi-section-base .h5,
.pi-section-base h6,
.pi-section-base .h6 {
  color: #ffffff;
}
.pi-section-base h1.pi-has-border:after,
.pi-section-base .h1.pi-has-border:after,
.pi-section-base h2.pi-has-border:after,
.pi-section-base .h2.pi-has-border:after,
.pi-section-base h3.pi-has-border:after,
.pi-section-base .h3.pi-has-border:after,
.pi-section-base h4.pi-has-border:after,
.pi-section-base .h4.pi-has-border:after,
.pi-section-base h5.pi-has-border:after,
.pi-section-base .h5.pi-has-border:after,
.pi-section-base h6.pi-has-border:after,
.pi-section-base .h6.pi-has-border:after {
  background: #ffffff;
}
.pi-section-base h1.pi-has-base-border:after,
.pi-section-base .h1.pi-has-base-border:after,
.pi-section-base h2.pi-has-base-border:after,
.pi-section-base .h2.pi-has-base-border:after,
.pi-section-base h3.pi-has-base-border:after,
.pi-section-base .h3.pi-has-base-border:after,
.pi-section-base h4.pi-has-base-border:after,
.pi-section-base .h4.pi-has-base-border:after,
.pi-section-base h5.pi-has-base-border:after,
.pi-section-base .h5.pi-has-base-border:after,
.pi-section-base h6.pi-has-base-border:after,
.pi-section-base .h6.pi-has-base-border:after {
  background: #609ddb;
}
.pi-section-base h1.pi-has-bg:after,
.pi-section-base .h1.pi-has-bg:after,
.pi-section-base h2.pi-has-bg:after,
.pi-section-base .h2.pi-has-bg:after,
.pi-section-base h3.pi-has-bg:after,
.pi-section-base .h3.pi-has-bg:after,
.pi-section-base h4.pi-has-bg:after,
.pi-section-base .h4.pi-has-bg:after,
.pi-section-base h5.pi-has-bg:after,
.pi-section-base .h5.pi-has-bg:after,
.pi-section-base h6.pi-has-bg:after,
.pi-section-base .h6.pi-has-bg:after {
  background: url('../img/bg-heading-blue.png');
}
.pi-section-base h1 a.pi-link-dark,
.pi-section-base .h1 a.pi-link-dark,
.pi-section-base h2 a.pi-link-dark,
.pi-section-base .h2 a.pi-link-dark,
.pi-section-base h3 a.pi-link-dark,
.pi-section-base .h3 a.pi-link-dark,
.pi-section-base h4 a.pi-link-dark,
.pi-section-base .h4 a.pi-link-dark,
.pi-section-base h5 a.pi-link-dark,
.pi-section-base .h5 a.pi-link-dark,
.pi-section-base h6 a.pi-link-dark,
.pi-section-base .h6 a.pi-link-dark {
  color: #ffffff;
}
.pi-section-base h1 a.pi-link-dark:hover,
.pi-section-base .h1 a.pi-link-dark:hover,
.pi-section-base h2 a.pi-link-dark:hover,
.pi-section-base .h2 a.pi-link-dark:hover,
.pi-section-base h3 a.pi-link-dark:hover,
.pi-section-base .h3 a.pi-link-dark:hover,
.pi-section-base h4 a.pi-link-dark:hover,
.pi-section-base .h4 a.pi-link-dark:hover,
.pi-section-base h5 a.pi-link-dark:hover,
.pi-section-base .h5 a.pi-link-dark:hover,
.pi-section-base h6 a.pi-link-dark:hover,
.pi-section-base .h6 a.pi-link-dark:hover {
  color: #ffffff;
}
.pi-section-base h1 a.pi-link-white,
.pi-section-base .h1 a.pi-link-white,
.pi-section-base h2 a.pi-link-white,
.pi-section-base .h2 a.pi-link-white,
.pi-section-base h3 a.pi-link-white,
.pi-section-base .h3 a.pi-link-white,
.pi-section-base h4 a.pi-link-white,
.pi-section-base .h4 a.pi-link-white,
.pi-section-base h5 a.pi-link-white,
.pi-section-base .h5 a.pi-link-white,
.pi-section-base h6 a.pi-link-white,
.pi-section-base .h6 a.pi-link-white {
  color: #fff;
}
.pi-section-base h1 a.pi-link-white:hover,
.pi-section-base .h1 a.pi-link-white:hover,
.pi-section-base h2 a.pi-link-white:hover,
.pi-section-base .h2 a.pi-link-white:hover,
.pi-section-base h3 a.pi-link-white:hover,
.pi-section-base .h3 a.pi-link-white:hover,
.pi-section-base h4 a.pi-link-white:hover,
.pi-section-base .h4 a.pi-link-white:hover,
.pi-section-base h5 a.pi-link-white:hover,
.pi-section-base .h5 a.pi-link-white:hover,
.pi-section-base h6 a.pi-link-white:hover,
.pi-section-base .h6 a.pi-link-white:hover {
  color: #fff;
}
.pi-section-base h1 a.pi-link-no-style,
.pi-section-base .h1 a.pi-link-no-style,
.pi-section-base h2 a.pi-link-no-style,
.pi-section-base .h2 a.pi-link-no-style,
.pi-section-base h3 a.pi-link-no-style,
.pi-section-base .h3 a.pi-link-no-style,
.pi-section-base h4 a.pi-link-no-style,
.pi-section-base .h4 a.pi-link-no-style,
.pi-section-base h5 a.pi-link-no-style,
.pi-section-base .h5 a.pi-link-no-style,
.pi-section-base h6 a.pi-link-no-style,
.pi-section-base .h6 a.pi-link-no-style {
  color: #ffffff;
}
.pi-section-base h1 a.pi-link-no-style:hover,
.pi-section-base .h1 a.pi-link-no-style:hover,
.pi-section-base h2 a.pi-link-no-style:hover,
.pi-section-base .h2 a.pi-link-no-style:hover,
.pi-section-base h3 a.pi-link-no-style:hover,
.pi-section-base .h3 a.pi-link-no-style:hover,
.pi-section-base h4 a.pi-link-no-style:hover,
.pi-section-base .h4 a.pi-link-no-style:hover,
.pi-section-base h5 a.pi-link-no-style:hover,
.pi-section-base .h5 a.pi-link-no-style:hover,
.pi-section-base h6 a.pi-link-no-style:hover,
.pi-section-base .h6 a.pi-link-no-style:hover {
  color: #ffffff;
}
.pi-section-base abbr,
.pi-section-base acronym,
.pi-section-base strong,
.pi-section-base b {
  color: #ffffff;
  font-weight: 600;
}
.pi-section-base a {
  color: #ffffff;
}
.pi-section-base a:hover {
  color: #ffffff;
}
.pi-section-base a.pi-link-dark {
  color: #ffffff;
}
.pi-section-base a.pi-link-dark:hover {
  color: #ffffff;
}
.pi-section-base a.pi-link-white {
  color: #fff;
}
.pi-section-base a.pi-link-white:hover {
  color: #fff;
}
.pi-section-base a.pi-link-no-style {
  color: #dee7f0;
}
.pi-section-base a.pi-link-no-style:hover {
  color: #ffffff;
}
.pi-section-base .pi-link-dark a {
  color: #ffffff;
}
.pi-section-base .pi-link-dark a:hover {
  color: #ffffff;
}
.pi-section-base .pi-link-white a {
  color: #fff;
}
.pi-section-base .pi-link-white a:hover {
  color: #fff;
}
.pi-section-base .pi-link-no-style a {
  color: #dee7f0;
}
.pi-section-base .pi-link-no-style a:hover {
  color: #ffffff;
}
.pi-section-base .lead-20,
.pi-section-base .lead-22,
.pi-section-base .lead-24,
.pi-section-base .lead-26,
.pi-section-base .lead-28,
.pi-section-base .lead-30,
.pi-section-base .lead-32,
.pi-section-base .lead-34 {
  color: #ffffff;
}
.pi-section-base a strong,
.pi-section-base a b {
  color: #ffffff;
}
.pi-section-base a strong:hover,
.pi-section-base a b:hover {
  color: #ffffff;
}
.pi-section-white {
  color: #666e70;
}
.pi-section-white h1,
.pi-section-white .h1,
.pi-section-white h2,
.pi-section-white .h2,
.pi-section-white h3,
.pi-section-white .h3,
.pi-section-white h4,
.pi-section-white .h4,
.pi-section-white h5,
.pi-section-white .h5,
.pi-section-white h6,
.pi-section-white .h6 {
  color: #21252b;
}
.pi-section-white h1.pi-has-border:after,
.pi-section-white .h1.pi-has-border:after,
.pi-section-white h2.pi-has-border:after,
.pi-section-white .h2.pi-has-border:after,
.pi-section-white h3.pi-has-border:after,
.pi-section-white .h3.pi-has-border:after,
.pi-section-white h4.pi-has-border:after,
.pi-section-white .h4.pi-has-border:after,
.pi-section-white h5.pi-has-border:after,
.pi-section-white .h5.pi-has-border:after,
.pi-section-white h6.pi-has-border:after,
.pi-section-white .h6.pi-has-border:after {
  background: #21252b;
}
.pi-section-white h1.pi-has-base-border:after,
.pi-section-white .h1.pi-has-base-border:after,
.pi-section-white h2.pi-has-base-border:after,
.pi-section-white .h2.pi-has-base-border:after,
.pi-section-white h3.pi-has-base-border:after,
.pi-section-white .h3.pi-has-base-border:after,
.pi-section-white h4.pi-has-base-border:after,
.pi-section-white .h4.pi-has-base-border:after,
.pi-section-white h5.pi-has-base-border:after,
.pi-section-white .h5.pi-has-base-border:after,
.pi-section-white h6.pi-has-base-border:after,
.pi-section-white .h6.pi-has-base-border:after {
  background: #609ddb;
}
.pi-section-white h1.pi-has-bg:after,
.pi-section-white .h1.pi-has-bg:after,
.pi-section-white h2.pi-has-bg:after,
.pi-section-white .h2.pi-has-bg:after,
.pi-section-white h3.pi-has-bg:after,
.pi-section-white .h3.pi-has-bg:after,
.pi-section-white h4.pi-has-bg:after,
.pi-section-white .h4.pi-has-bg:after,
.pi-section-white h5.pi-has-bg:after,
.pi-section-white .h5.pi-has-bg:after,
.pi-section-white h6.pi-has-bg:after,
.pi-section-white .h6.pi-has-bg:after {
  background: url('images/bg-heading.png');
}
.pi-section-white h1 a.pi-link-dark,
.pi-section-white .h1 a.pi-link-dark,
.pi-section-white h2 a.pi-link-dark,
.pi-section-white .h2 a.pi-link-dark,
.pi-section-white h3 a.pi-link-dark,
.pi-section-white .h3 a.pi-link-dark,
.pi-section-white h4 a.pi-link-dark,
.pi-section-white .h4 a.pi-link-dark,
.pi-section-white h5 a.pi-link-dark,
.pi-section-white .h5 a.pi-link-dark,
.pi-section-white h6 a.pi-link-dark,
.pi-section-white .h6 a.pi-link-dark {
  color: #21252b;
}
.pi-section-white h1 a.pi-link-dark:hover,
.pi-section-white .h1 a.pi-link-dark:hover,
.pi-section-white h2 a.pi-link-dark:hover,
.pi-section-white .h2 a.pi-link-dark:hover,
.pi-section-white h3 a.pi-link-dark:hover,
.pi-section-white .h3 a.pi-link-dark:hover,
.pi-section-white h4 a.pi-link-dark:hover,
.pi-section-white .h4 a.pi-link-dark:hover,
.pi-section-white h5 a.pi-link-dark:hover,
.pi-section-white .h5 a.pi-link-dark:hover,
.pi-section-white h6 a.pi-link-dark:hover,
.pi-section-white .h6 a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-white h1 a.pi-link-white,
.pi-section-white .h1 a.pi-link-white,
.pi-section-white h2 a.pi-link-white,
.pi-section-white .h2 a.pi-link-white,
.pi-section-white h3 a.pi-link-white,
.pi-section-white .h3 a.pi-link-white,
.pi-section-white h4 a.pi-link-white,
.pi-section-white .h4 a.pi-link-white,
.pi-section-white h5 a.pi-link-white,
.pi-section-white .h5 a.pi-link-white,
.pi-section-white h6 a.pi-link-white,
.pi-section-white .h6 a.pi-link-white {
  color: #fff;
}
.pi-section-white h1 a.pi-link-white:hover,
.pi-section-white .h1 a.pi-link-white:hover,
.pi-section-white h2 a.pi-link-white:hover,
.pi-section-white .h2 a.pi-link-white:hover,
.pi-section-white h3 a.pi-link-white:hover,
.pi-section-white .h3 a.pi-link-white:hover,
.pi-section-white h4 a.pi-link-white:hover,
.pi-section-white .h4 a.pi-link-white:hover,
.pi-section-white h5 a.pi-link-white:hover,
.pi-section-white .h5 a.pi-link-white:hover,
.pi-section-white h6 a.pi-link-white:hover,
.pi-section-white .h6 a.pi-link-white:hover {
  color: #fff;
}
.pi-section-white h1 a.pi-link-no-style,
.pi-section-white .h1 a.pi-link-no-style,
.pi-section-white h2 a.pi-link-no-style,
.pi-section-white .h2 a.pi-link-no-style,
.pi-section-white h3 a.pi-link-no-style,
.pi-section-white .h3 a.pi-link-no-style,
.pi-section-white h4 a.pi-link-no-style,
.pi-section-white .h4 a.pi-link-no-style,
.pi-section-white h5 a.pi-link-no-style,
.pi-section-white .h5 a.pi-link-no-style,
.pi-section-white h6 a.pi-link-no-style,
.pi-section-white .h6 a.pi-link-no-style {
  color: #21252b;
}
.pi-section-white h1 a.pi-link-no-style:hover,
.pi-section-white .h1 a.pi-link-no-style:hover,
.pi-section-white h2 a.pi-link-no-style:hover,
.pi-section-white .h2 a.pi-link-no-style:hover,
.pi-section-white h3 a.pi-link-no-style:hover,
.pi-section-white .h3 a.pi-link-no-style:hover,
.pi-section-white h4 a.pi-link-no-style:hover,
.pi-section-white .h4 a.pi-link-no-style:hover,
.pi-section-white h5 a.pi-link-no-style:hover,
.pi-section-white .h5 a.pi-link-no-style:hover,
.pi-section-white h6 a.pi-link-no-style:hover,
.pi-section-white .h6 a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-white abbr,
.pi-section-white acronym,
.pi-section-white strong,
.pi-section-white b {
  color: #21252b;
  font-weight: 600;
}
.pi-section-white a {
  color: #609ddb;
}
.pi-section-white a:hover {
  color: #487ab9;
}
.pi-section-white a.pi-link-dark {
  color: #21252b;
}
.pi-section-white a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-white a.pi-link-white {
  color: #fff;
}
.pi-section-white a.pi-link-white:hover {
  color: #fff;
}
.pi-section-white a.pi-link-no-style {
  color: #666e70;
}
.pi-section-white a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-white .pi-link-dark a {
  color: #21252b;
}
.pi-section-white .pi-link-dark a:hover {
  color: #609ddb;
}
.pi-section-white .pi-link-white a {
  color: #fff;
}
.pi-section-white .pi-link-white a:hover {
  color: #fff;
}
.pi-section-white .pi-link-no-style a {
  color: #666e70;
}
.pi-section-white .pi-link-no-style a:hover {
  color: #609ddb;
}
.pi-section-white .lead-20,
.pi-section-white .lead-22,
.pi-section-white .lead-24,
.pi-section-white .lead-26,
.pi-section-white .lead-28,
.pi-section-white .lead-30,
.pi-section-white .lead-32,
.pi-section-white .lead-34 {
  color: #3c4446;
}
.pi-section-white a strong,
.pi-section-white a b {
  color: #609ddb;
}
.pi-section-white a strong:hover,
.pi-section-white a b:hover {
  color: #487ab9;
}
.pi-section-grey {
  color: #666e70;
}
.pi-section-grey h1,
.pi-section-grey .h1,
.pi-section-grey h2,
.pi-section-grey .h2,
.pi-section-grey h3,
.pi-section-grey .h3,
.pi-section-grey h4,
.pi-section-grey .h4,
.pi-section-grey h5,
.pi-section-grey .h5,
.pi-section-grey h6,
.pi-section-grey .h6 {
  color: #21252b;
}
.pi-section-grey h1.pi-has-border:after,
.pi-section-grey .h1.pi-has-border:after,
.pi-section-grey h2.pi-has-border:after,
.pi-section-grey .h2.pi-has-border:after,
.pi-section-grey h3.pi-has-border:after,
.pi-section-grey .h3.pi-has-border:after,
.pi-section-grey h4.pi-has-border:after,
.pi-section-grey .h4.pi-has-border:after,
.pi-section-grey h5.pi-has-border:after,
.pi-section-grey .h5.pi-has-border:after,
.pi-section-grey h6.pi-has-border:after,
.pi-section-grey .h6.pi-has-border:after {
  background: #21252b;
}
.pi-section-grey h1.pi-has-base-border:after,
.pi-section-grey .h1.pi-has-base-border:after,
.pi-section-grey h2.pi-has-base-border:after,
.pi-section-grey .h2.pi-has-base-border:after,
.pi-section-grey h3.pi-has-base-border:after,
.pi-section-grey .h3.pi-has-base-border:after,
.pi-section-grey h4.pi-has-base-border:after,
.pi-section-grey .h4.pi-has-base-border:after,
.pi-section-grey h5.pi-has-base-border:after,
.pi-section-grey .h5.pi-has-base-border:after,
.pi-section-grey h6.pi-has-base-border:after,
.pi-section-grey .h6.pi-has-base-border:after {
  background: #609ddb;
}
.pi-section-grey h1.pi-has-bg:after,
.pi-section-grey .h1.pi-has-bg:after,
.pi-section-grey h2.pi-has-bg:after,
.pi-section-grey .h2.pi-has-bg:after,
.pi-section-grey h3.pi-has-bg:after,
.pi-section-grey .h3.pi-has-bg:after,
.pi-section-grey h4.pi-has-bg:after,
.pi-section-grey .h4.pi-has-bg:after,
.pi-section-grey h5.pi-has-bg:after,
.pi-section-grey .h5.pi-has-bg:after,
.pi-section-grey h6.pi-has-bg:after,
.pi-section-grey .h6.pi-has-bg:after {
  background: url('../img/bg-heading-grey.png');
}
.pi-section-grey h1 a.pi-link-dark,
.pi-section-grey .h1 a.pi-link-dark,
.pi-section-grey h2 a.pi-link-dark,
.pi-section-grey .h2 a.pi-link-dark,
.pi-section-grey h3 a.pi-link-dark,
.pi-section-grey .h3 a.pi-link-dark,
.pi-section-grey h4 a.pi-link-dark,
.pi-section-grey .h4 a.pi-link-dark,
.pi-section-grey h5 a.pi-link-dark,
.pi-section-grey .h5 a.pi-link-dark,
.pi-section-grey h6 a.pi-link-dark,
.pi-section-grey .h6 a.pi-link-dark {
  color: #21252b;
}
.pi-section-grey h1 a.pi-link-dark:hover,
.pi-section-grey .h1 a.pi-link-dark:hover,
.pi-section-grey h2 a.pi-link-dark:hover,
.pi-section-grey .h2 a.pi-link-dark:hover,
.pi-section-grey h3 a.pi-link-dark:hover,
.pi-section-grey .h3 a.pi-link-dark:hover,
.pi-section-grey h4 a.pi-link-dark:hover,
.pi-section-grey .h4 a.pi-link-dark:hover,
.pi-section-grey h5 a.pi-link-dark:hover,
.pi-section-grey .h5 a.pi-link-dark:hover,
.pi-section-grey h6 a.pi-link-dark:hover,
.pi-section-grey .h6 a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-grey h1 a.pi-link-white,
.pi-section-grey .h1 a.pi-link-white,
.pi-section-grey h2 a.pi-link-white,
.pi-section-grey .h2 a.pi-link-white,
.pi-section-grey h3 a.pi-link-white,
.pi-section-grey .h3 a.pi-link-white,
.pi-section-grey h4 a.pi-link-white,
.pi-section-grey .h4 a.pi-link-white,
.pi-section-grey h5 a.pi-link-white,
.pi-section-grey .h5 a.pi-link-white,
.pi-section-grey h6 a.pi-link-white,
.pi-section-grey .h6 a.pi-link-white {
  color: #fff;
}
.pi-section-grey h1 a.pi-link-white:hover,
.pi-section-grey .h1 a.pi-link-white:hover,
.pi-section-grey h2 a.pi-link-white:hover,
.pi-section-grey .h2 a.pi-link-white:hover,
.pi-section-grey h3 a.pi-link-white:hover,
.pi-section-grey .h3 a.pi-link-white:hover,
.pi-section-grey h4 a.pi-link-white:hover,
.pi-section-grey .h4 a.pi-link-white:hover,
.pi-section-grey h5 a.pi-link-white:hover,
.pi-section-grey .h5 a.pi-link-white:hover,
.pi-section-grey h6 a.pi-link-white:hover,
.pi-section-grey .h6 a.pi-link-white:hover {
  color: #fff;
}
.pi-section-grey h1 a.pi-link-no-style,
.pi-section-grey .h1 a.pi-link-no-style,
.pi-section-grey h2 a.pi-link-no-style,
.pi-section-grey .h2 a.pi-link-no-style,
.pi-section-grey h3 a.pi-link-no-style,
.pi-section-grey .h3 a.pi-link-no-style,
.pi-section-grey h4 a.pi-link-no-style,
.pi-section-grey .h4 a.pi-link-no-style,
.pi-section-grey h5 a.pi-link-no-style,
.pi-section-grey .h5 a.pi-link-no-style,
.pi-section-grey h6 a.pi-link-no-style,
.pi-section-grey .h6 a.pi-link-no-style {
  color: #21252b;
}
.pi-section-grey h1 a.pi-link-no-style:hover,
.pi-section-grey .h1 a.pi-link-no-style:hover,
.pi-section-grey h2 a.pi-link-no-style:hover,
.pi-section-grey .h2 a.pi-link-no-style:hover,
.pi-section-grey h3 a.pi-link-no-style:hover,
.pi-section-grey .h3 a.pi-link-no-style:hover,
.pi-section-grey h4 a.pi-link-no-style:hover,
.pi-section-grey .h4 a.pi-link-no-style:hover,
.pi-section-grey h5 a.pi-link-no-style:hover,
.pi-section-grey .h5 a.pi-link-no-style:hover,
.pi-section-grey h6 a.pi-link-no-style:hover,
.pi-section-grey .h6 a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-grey abbr,
.pi-section-grey acronym,
.pi-section-grey strong,
.pi-section-grey b {
  color: #21252b;
  font-weight: 600;
}
.pi-section-grey a {
  color: #609ddb;
}
.pi-section-grey a:hover {
  color: #487ab9;
}
.pi-section-grey a.pi-link-dark {
  color: #21252b;
}
.pi-section-grey a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-grey a.pi-link-white {
  color: #fff;
}
.pi-section-grey a.pi-link-white:hover {
  color: #fff;
}
.pi-section-grey a.pi-link-no-style {
  color: #666e70;
}
.pi-section-grey a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-grey .pi-link-dark a {
  color: #21252b;
}
.pi-section-grey .pi-link-dark a:hover {
  color: #609ddb;
}
.pi-section-grey .pi-link-white a {
  color: #fff;
}
.pi-section-grey .pi-link-white a:hover {
  color: #fff;
}
.pi-section-grey .pi-link-no-style a {
  color: #666e70;
}
.pi-section-grey .pi-link-no-style a:hover {
  color: #609ddb;
}
.pi-section-grey .lead-20,
.pi-section-grey .lead-22,
.pi-section-grey .lead-24,
.pi-section-grey .lead-26,
.pi-section-grey .lead-28,
.pi-section-grey .lead-30,
.pi-section-grey .lead-32,
.pi-section-grey .lead-34 {
  color: #4c5355;
}
.pi-section-grey a strong,
.pi-section-grey a b {
  color: #609ddb;
}
.pi-section-grey a strong:hover,
.pi-section-grey a b:hover {
  color: #487ab9;
}
.pi-section-dark {
  color: #858d91;
}
.pi-section-dark h1,
.pi-section-dark .h1,
.pi-section-dark h2,
.pi-section-dark .h2,
.pi-section-dark h3,
.pi-section-dark .h3,
.pi-section-dark h4,
.pi-section-dark .h4,
.pi-section-dark h5,
.pi-section-dark .h5,
.pi-section-dark h6,
.pi-section-dark .h6 {
  color: #ffffff;
}
.pi-section-dark h1.pi-has-border:after,
.pi-section-dark .h1.pi-has-border:after,
.pi-section-dark h2.pi-has-border:after,
.pi-section-dark .h2.pi-has-border:after,
.pi-section-dark h3.pi-has-border:after,
.pi-section-dark .h3.pi-has-border:after,
.pi-section-dark h4.pi-has-border:after,
.pi-section-dark .h4.pi-has-border:after,
.pi-section-dark h5.pi-has-border:after,
.pi-section-dark .h5.pi-has-border:after,
.pi-section-dark h6.pi-has-border:after,
.pi-section-dark .h6.pi-has-border:after {
  background: #ffffff;
}
.pi-section-dark h1.pi-has-base-border:after,
.pi-section-dark .h1.pi-has-base-border:after,
.pi-section-dark h2.pi-has-base-border:after,
.pi-section-dark .h2.pi-has-base-border:after,
.pi-section-dark h3.pi-has-base-border:after,
.pi-section-dark .h3.pi-has-base-border:after,
.pi-section-dark h4.pi-has-base-border:after,
.pi-section-dark .h4.pi-has-base-border:after,
.pi-section-dark h5.pi-has-base-border:after,
.pi-section-dark .h5.pi-has-base-border:after,
.pi-section-dark h6.pi-has-base-border:after,
.pi-section-dark .h6.pi-has-base-border:after {
  background: #609ddb;
}
.pi-section-dark h1.pi-has-bg:after,
.pi-section-dark .h1.pi-has-bg:after,
.pi-section-dark h2.pi-has-bg:after,
.pi-section-dark .h2.pi-has-bg:after,
.pi-section-dark h3.pi-has-bg:after,
.pi-section-dark .h3.pi-has-bg:after,
.pi-section-dark h4.pi-has-bg:after,
.pi-section-dark .h4.pi-has-bg:after,
.pi-section-dark h5.pi-has-bg:after,
.pi-section-dark .h5.pi-has-bg:after,
.pi-section-dark h6.pi-has-bg:after,
.pi-section-dark .h6.pi-has-bg:after {
  background: url('../img/bg-heading-dark.png');
}
.pi-section-dark h1 a.pi-link-dark,
.pi-section-dark .h1 a.pi-link-dark,
.pi-section-dark h2 a.pi-link-dark,
.pi-section-dark .h2 a.pi-link-dark,
.pi-section-dark h3 a.pi-link-dark,
.pi-section-dark .h3 a.pi-link-dark,
.pi-section-dark h4 a.pi-link-dark,
.pi-section-dark .h4 a.pi-link-dark,
.pi-section-dark h5 a.pi-link-dark,
.pi-section-dark .h5 a.pi-link-dark,
.pi-section-dark h6 a.pi-link-dark,
.pi-section-dark .h6 a.pi-link-dark {
  color: #ffffff;
}
.pi-section-dark h1 a.pi-link-dark:hover,
.pi-section-dark .h1 a.pi-link-dark:hover,
.pi-section-dark h2 a.pi-link-dark:hover,
.pi-section-dark .h2 a.pi-link-dark:hover,
.pi-section-dark h3 a.pi-link-dark:hover,
.pi-section-dark .h3 a.pi-link-dark:hover,
.pi-section-dark h4 a.pi-link-dark:hover,
.pi-section-dark .h4 a.pi-link-dark:hover,
.pi-section-dark h5 a.pi-link-dark:hover,
.pi-section-dark .h5 a.pi-link-dark:hover,
.pi-section-dark h6 a.pi-link-dark:hover,
.pi-section-dark .h6 a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-dark h1 a.pi-link-white,
.pi-section-dark .h1 a.pi-link-white,
.pi-section-dark h2 a.pi-link-white,
.pi-section-dark .h2 a.pi-link-white,
.pi-section-dark h3 a.pi-link-white,
.pi-section-dark .h3 a.pi-link-white,
.pi-section-dark h4 a.pi-link-white,
.pi-section-dark .h4 a.pi-link-white,
.pi-section-dark h5 a.pi-link-white,
.pi-section-dark .h5 a.pi-link-white,
.pi-section-dark h6 a.pi-link-white,
.pi-section-dark .h6 a.pi-link-white {
  color: #fff;
}
.pi-section-dark h1 a.pi-link-white:hover,
.pi-section-dark .h1 a.pi-link-white:hover,
.pi-section-dark h2 a.pi-link-white:hover,
.pi-section-dark .h2 a.pi-link-white:hover,
.pi-section-dark h3 a.pi-link-white:hover,
.pi-section-dark .h3 a.pi-link-white:hover,
.pi-section-dark h4 a.pi-link-white:hover,
.pi-section-dark .h4 a.pi-link-white:hover,
.pi-section-dark h5 a.pi-link-white:hover,
.pi-section-dark .h5 a.pi-link-white:hover,
.pi-section-dark h6 a.pi-link-white:hover,
.pi-section-dark .h6 a.pi-link-white:hover {
  color: #fff;
}
.pi-section-dark h1 a.pi-link-no-style,
.pi-section-dark .h1 a.pi-link-no-style,
.pi-section-dark h2 a.pi-link-no-style,
.pi-section-dark .h2 a.pi-link-no-style,
.pi-section-dark h3 a.pi-link-no-style,
.pi-section-dark .h3 a.pi-link-no-style,
.pi-section-dark h4 a.pi-link-no-style,
.pi-section-dark .h4 a.pi-link-no-style,
.pi-section-dark h5 a.pi-link-no-style,
.pi-section-dark .h5 a.pi-link-no-style,
.pi-section-dark h6 a.pi-link-no-style,
.pi-section-dark .h6 a.pi-link-no-style {
  color: #ffffff;
}
.pi-section-dark h1 a.pi-link-no-style:hover,
.pi-section-dark .h1 a.pi-link-no-style:hover,
.pi-section-dark h2 a.pi-link-no-style:hover,
.pi-section-dark .h2 a.pi-link-no-style:hover,
.pi-section-dark h3 a.pi-link-no-style:hover,
.pi-section-dark .h3 a.pi-link-no-style:hover,
.pi-section-dark h4 a.pi-link-no-style:hover,
.pi-section-dark .h4 a.pi-link-no-style:hover,
.pi-section-dark h5 a.pi-link-no-style:hover,
.pi-section-dark .h5 a.pi-link-no-style:hover,
.pi-section-dark h6 a.pi-link-no-style:hover,
.pi-section-dark .h6 a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-dark abbr,
.pi-section-dark acronym,
.pi-section-dark strong,
.pi-section-dark b {
  color: #aaa;
}
.pi-section-dark a {
  color: #aaa;
  font-weight: normal;
}
.pi-section-dark strong {
  color: #aaa;
  font-weight: normal;
}
.pi-section-dark a:hover {
  color: #e89b18;
}
.pi-section-dark a.pi-link-dark {
  color: #ffffff;
}
.pi-section-dark a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-dark a.pi-link-white {
  color: #fff;
}
.pi-section-dark a.pi-link-white:hover {
  color: #fff;
}
.pi-section-dark a.pi-link-no-style {
  color: #858d91;
}
.pi-section-dark a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-dark .pi-link-dark a {
  color: #ffffff;
}
.pi-section-dark .pi-link-dark a:hover {
  color: #609ddb;
}
.pi-section-dark .pi-link-white a {
  color: #fff;
}
.pi-section-dark .pi-link-white a:hover {
  color: #fff;
}
.pi-section-dark .pi-link-no-style a {
  color: #858d91;
}
.pi-section-dark .pi-link-no-style a:hover {
  color: #609ddb;
}
.pi-section-dark .lead-20,
.pi-section-dark .lead-22,
.pi-section-dark .lead-24,
.pi-section-dark .lead-26,
.pi-section-dark .lead-28,
.pi-section-dark .lead-30,
.pi-section-dark .lead-32,
.pi-section-dark .lead-34 {
  color: #bbc8ce;
}
.pi-section-dark a strong,
.pi-section-dark a b {
  color: #609ddb;
}
.pi-section-dark a strong:hover,
.pi-section-dark a b:hover {
  color: #487ab9;
}
.pi-section-parallax {
  color: rgba(250, 250, 250, 0.7);
}
.pi-section-parallax h1,
.pi-section-parallax .h1,
.pi-section-parallax h2,
.pi-section-parallax .h2,
.pi-section-parallax h3,
.pi-section-parallax .h3,
.pi-section-parallax h4,
.pi-section-parallax .h4,
.pi-section-parallax h5,
.pi-section-parallax .h5,
.pi-section-parallax h6,
.pi-section-parallax .h6 {
  color: #ffffff;
}
.pi-section-parallax h1.pi-has-border:after,
.pi-section-parallax .h1.pi-has-border:after,
.pi-section-parallax h2.pi-has-border:after,
.pi-section-parallax .h2.pi-has-border:after,
.pi-section-parallax h3.pi-has-border:after,
.pi-section-parallax .h3.pi-has-border:after,
.pi-section-parallax h4.pi-has-border:after,
.pi-section-parallax .h4.pi-has-border:after,
.pi-section-parallax h5.pi-has-border:after,
.pi-section-parallax .h5.pi-has-border:after,
.pi-section-parallax h6.pi-has-border:after,
.pi-section-parallax .h6.pi-has-border:after {
  background: #ffffff;
}
.pi-section-parallax h1.pi-has-base-border:after,
.pi-section-parallax .h1.pi-has-base-border:after,
.pi-section-parallax h2.pi-has-base-border:after,
.pi-section-parallax .h2.pi-has-base-border:after,
.pi-section-parallax h3.pi-has-base-border:after,
.pi-section-parallax .h3.pi-has-base-border:after,
.pi-section-parallax h4.pi-has-base-border:after,
.pi-section-parallax .h4.pi-has-base-border:after,
.pi-section-parallax h5.pi-has-base-border:after,
.pi-section-parallax .h5.pi-has-base-border:after,
.pi-section-parallax h6.pi-has-base-border:after,
.pi-section-parallax .h6.pi-has-base-border:after {
  background: #609ddb;
}
.pi-section-parallax h1.pi-has-bg:after,
.pi-section-parallax .h1.pi-has-bg:after,
.pi-section-parallax h2.pi-has-bg:after,
.pi-section-parallax .h2.pi-has-bg:after,
.pi-section-parallax h3.pi-has-bg:after,
.pi-section-parallax .h3.pi-has-bg:after,
.pi-section-parallax h4.pi-has-bg:after,
.pi-section-parallax .h4.pi-has-bg:after,
.pi-section-parallax h5.pi-has-bg:after,
.pi-section-parallax .h5.pi-has-bg:after,
.pi-section-parallax h6.pi-has-bg:after,
.pi-section-parallax .h6.pi-has-bg:after {
  background: url('../img/bg-heading-darker.png');
}
.pi-section-parallax h1 a.pi-link-dark,
.pi-section-parallax .h1 a.pi-link-dark,
.pi-section-parallax h2 a.pi-link-dark,
.pi-section-parallax .h2 a.pi-link-dark,
.pi-section-parallax h3 a.pi-link-dark,
.pi-section-parallax .h3 a.pi-link-dark,
.pi-section-parallax h4 a.pi-link-dark,
.pi-section-parallax .h4 a.pi-link-dark,
.pi-section-parallax h5 a.pi-link-dark,
.pi-section-parallax .h5 a.pi-link-dark,
.pi-section-parallax h6 a.pi-link-dark,
.pi-section-parallax .h6 a.pi-link-dark {
  color: #ffffff;
}
.pi-section-parallax h1 a.pi-link-dark:hover,
.pi-section-parallax .h1 a.pi-link-dark:hover,
.pi-section-parallax h2 a.pi-link-dark:hover,
.pi-section-parallax .h2 a.pi-link-dark:hover,
.pi-section-parallax h3 a.pi-link-dark:hover,
.pi-section-parallax .h3 a.pi-link-dark:hover,
.pi-section-parallax h4 a.pi-link-dark:hover,
.pi-section-parallax .h4 a.pi-link-dark:hover,
.pi-section-parallax h5 a.pi-link-dark:hover,
.pi-section-parallax .h5 a.pi-link-dark:hover,
.pi-section-parallax h6 a.pi-link-dark:hover,
.pi-section-parallax .h6 a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-parallax h1 a.pi-link-white,
.pi-section-parallax .h1 a.pi-link-white,
.pi-section-parallax h2 a.pi-link-white,
.pi-section-parallax .h2 a.pi-link-white,
.pi-section-parallax h3 a.pi-link-white,
.pi-section-parallax .h3 a.pi-link-white,
.pi-section-parallax h4 a.pi-link-white,
.pi-section-parallax .h4 a.pi-link-white,
.pi-section-parallax h5 a.pi-link-white,
.pi-section-parallax .h5 a.pi-link-white,
.pi-section-parallax h6 a.pi-link-white,
.pi-section-parallax .h6 a.pi-link-white {
  color: #fff;
}
.pi-section-parallax h1 a.pi-link-white:hover,
.pi-section-parallax .h1 a.pi-link-white:hover,
.pi-section-parallax h2 a.pi-link-white:hover,
.pi-section-parallax .h2 a.pi-link-white:hover,
.pi-section-parallax h3 a.pi-link-white:hover,
.pi-section-parallax .h3 a.pi-link-white:hover,
.pi-section-parallax h4 a.pi-link-white:hover,
.pi-section-parallax .h4 a.pi-link-white:hover,
.pi-section-parallax h5 a.pi-link-white:hover,
.pi-section-parallax .h5 a.pi-link-white:hover,
.pi-section-parallax h6 a.pi-link-white:hover,
.pi-section-parallax .h6 a.pi-link-white:hover {
  color: #fff;
}
.pi-section-parallax h1 a.pi-link-no-style,
.pi-section-parallax .h1 a.pi-link-no-style,
.pi-section-parallax h2 a.pi-link-no-style,
.pi-section-parallax .h2 a.pi-link-no-style,
.pi-section-parallax h3 a.pi-link-no-style,
.pi-section-parallax .h3 a.pi-link-no-style,
.pi-section-parallax h4 a.pi-link-no-style,
.pi-section-parallax .h4 a.pi-link-no-style,
.pi-section-parallax h5 a.pi-link-no-style,
.pi-section-parallax .h5 a.pi-link-no-style,
.pi-section-parallax h6 a.pi-link-no-style,
.pi-section-parallax .h6 a.pi-link-no-style {
  color: #ffffff;
}
.pi-section-parallax h1 a.pi-link-no-style:hover,
.pi-section-parallax .h1 a.pi-link-no-style:hover,
.pi-section-parallax h2 a.pi-link-no-style:hover,
.pi-section-parallax .h2 a.pi-link-no-style:hover,
.pi-section-parallax h3 a.pi-link-no-style:hover,
.pi-section-parallax .h3 a.pi-link-no-style:hover,
.pi-section-parallax h4 a.pi-link-no-style:hover,
.pi-section-parallax .h4 a.pi-link-no-style:hover,
.pi-section-parallax h5 a.pi-link-no-style:hover,
.pi-section-parallax .h5 a.pi-link-no-style:hover,
.pi-section-parallax h6 a.pi-link-no-style:hover,
.pi-section-parallax .h6 a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-parallax abbr,
.pi-section-parallax acronym,
.pi-section-parallax strong,
.pi-section-parallax b {
  color: rgba(250, 250, 250, 0.8);
  font-weight: 600;
}
.pi-section-parallax a {
  color: #609ddb;
}
.pi-section-parallax a:hover {
  color: #487ab9;
}
.pi-section-parallax a.pi-link-dark {
  color: #ffffff;
}
.pi-section-parallax a.pi-link-dark:hover {
  color: #609ddb;
}
.pi-section-parallax a.pi-link-white {
  color: #fff;
}
.pi-section-parallax a.pi-link-white:hover {
  color: #fff;
}
.pi-section-parallax a.pi-link-no-style {
  color: rgba(250, 250, 250, 0.7);
}
.pi-section-parallax a.pi-link-no-style:hover {
  color: #609ddb;
}
.pi-section-parallax .pi-link-dark a {
  color: #ffffff;
}
.pi-section-parallax .pi-link-dark a:hover {
  color: #609ddb;
}
.pi-section-parallax .pi-link-white a {
  color: #fff;
}
.pi-section-parallax .pi-link-white a:hover {
  color: #fff;
}
.pi-section-parallax .pi-link-no-style a {
  color: rgba(250, 250, 250, 0.7);
}
.pi-section-parallax .pi-link-no-style a:hover {
  color: #609ddb;
}
.pi-section-parallax .lead-20,
.pi-section-parallax .lead-22,
.pi-section-parallax .lead-24,
.pi-section-parallax .lead-26,
.pi-section-parallax .lead-28,
.pi-section-parallax .lead-30,
.pi-section-parallax .lead-32,
.pi-section-parallax .lead-34 {
  color: rgba(250, 250, 250, 0.85);
}
.pi-section-parallax a strong,
.pi-section-parallax a b {
  color: #609ddb;
}
.pi-section-parallax a strong:hover,
.pi-section-parallax a b:hover {
  color: #487ab9;
}
.pi-icon-circle,
.pi-icon-square {
  background: #f4f6f6;
  color: #2e343c;
  text-shadow: 0 1px 0 #fff;
}
.pi-icon-base {
  background: #36597b;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-icon-dark {
  background: #2e343c;
  color: #609ddb;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-icon-white {
  background: #fff;
  color: #2e343c;
  text-shadow: 0 1px 0 #fff;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
}
.pi-text-base {
  color: #609ddb !important;
}
.pi-text-base-dark {
  color: #35649f !important;
}
.pi-text-bg-base {
  background: #d5eafc;
  color: #245f8c;
}
::selection {
  background: #487ab9;
  color: #fff;
}
::-moz-selection {
  background: #487ab9;
  color: #fff;
}
::-webkit-selection {
  background: #487ab9;
  color: #fff;
}
