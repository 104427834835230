.pi-texture {
    background: url(images/hexagon.png) repeat;
}

.pi-section-about {
    padding: 30px 40px 26px;
}

.minservices {
    min-height: 70px;
}

.footercover {
    background-image: url(images/consulting.jpg);
}
