
.pi-pagenav ul {
  list-style: none;
  padding-left: 0;
}
.pi-pagenav li {
  display: inline-block;
}
.pi-pagenav a {
  display: inline-block;
  padding: 6px 10px 6px;
  border: 1px solid transparent;
  line-height: 16px;
  font-size: 14px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  white-space: normal;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.pi-pagenav a i,
.pi-pagenav a img {
  margin-left: 0px;
}
.pi-pagenav a img {
  width: 16px;
  margin-top: -2px;
}
.pi-pagenav a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.pi-pagenav a:hover,
.pi-pagenav a:focus {
  text-decoration: none;
}
.pi-pagenav a:active,
.pi-pagenav a.active {
  outline: 0;
}
.pi-pagenav.pi-big a {
  padding: 9px 13px 9px;
  line-height: 18px;
}
.pi-section-white .pi-pagenav a,
.pi-section-grey .pi-pagenav a {
  background: #f4f6f6;
  border-color: #e3e9e9;
  color: #586266;
  box-shadow: inset 0 1px 0 #f8fafa;
  text-shadow: 0 1px 0 #ffffff;
}
.pi-section-white .pi-pagenav a:hover,
.pi-section-grey .pi-pagenav a:hover {
  background: #a5b3b4;
  border-color: #9eadae;
  color: #ffffff;
  box-shadow: inset 0 1px 0 #aebbbc;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.pi-section-white .pi-pagenav a.pi-active,
.pi-section-grey .pi-pagenav a.pi-active {
  background: #609ddb;
  border-color: #5491d5;
  color: #ffffff;
  box-shadow: inset 0 1px 0 #6aa7df;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-section-white .pi-pagenav a.pi-active:hover,
.pi-section-grey .pi-pagenav a.pi-active:hover {
  background: #487ab9;
  border-color: #4070b1;
  color: #ffffff;
  box-shadow: inset 0 1px 0 #4f86c0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-section-base .pi-pagenav a {
  background: #5b96d2;
  border-color: #6aa0d6;
  color: #ffffff;
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
}
.pi-section-base .pi-pagenav a:hover {
  background: #609ddb;
  border-color: #78aee2;
  color: #ffffff;
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
}
.pi-section-base .pi-pagenav a.pi-active {
  background: #4478b2;
  border-color: #3f71ac;
  color: #ffffff;
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
}
.pi-section-base .pi-pagenav a.pi-active:hover {
  background: #3868a3;
  border-color: #34629d;
  color: #ffffff;
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
}
.pi-section-dark .pi-pagenav a,
.pi-section-parallax .pi-pagenav a {
  background: #1b1f24;
  border-color: #191d21;
  color: #a5afb8;
  box-shadow: inset 0 1px 0 #1e2127;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-section-dark .pi-pagenav a:hover,
.pi-section-parallax .pi-pagenav a:hover {
  background: #16191d;
  border-color: #14171a;
  color: #9ca7b1;
  box-shadow: inset 0 1px 0 #181a1f;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-section-dark .pi-pagenav a.pi-active,
.pi-section-parallax .pi-pagenav a.pi-active {
  background: #609ddb;
  border-color: #5491d5;
  color: #ffffff;
  box-shadow: inset 0 1px 0 #6aa7df;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.pi-section-dark .pi-pagenav a.pi-active:hover,
.pi-section-parallax .pi-pagenav a.pi-active:hover {
  background: #487ab9;
  border-color: #4070b1;
  color: #ffffff;
  box-shadow: inset 0 1px 0 #4f86c0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.pi-pagenav ul li span {
  display: inline-block;
  padding: 6px 10px 6px;
  border: 1px solid transparent;
  line-height: 16px;
  font-size: 14px;
  vertical-align: middle;
  outline: none;
  white-space: normal;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
